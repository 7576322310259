<template>
	<div class="order_wrap">
		<v-container class="fixed_wrap">
			<MStepper />
			<h1 class="tit_page px-6">고객 인증</h1>
			<v-form class="form px-6" ref="authForm">
				<div class="wrap_input_confirm">
					<v-text-field
						class="intime"
						outlined
						dense
						placeholder="인증번호 6자리 입력"
						inputmode="numeric"
						maxlength="6"
						v-mask="'######'"
						v-model="authNo"
						:rules="rules.authNo"
					/>
					<div class="time">{{ time }}</div>
					<v-btn
						color="primary"
						height="48px"
						depressed
						dense
						class="btn_intime"
						@click="resend"
					>
						재전송
					</v-btn>
				</div>
			</v-form>
			<ul class="box_ex">
				<li>3분 이내로 인증번호(6자리)를 입력해 주세요.</li>
				<li>입력시간 초과 시 “재전송” 버튼을 눌러주세요.</li>
				<li>인증 시 개인정보이용과 신용정보조회에 동의하게 됩니다.</li>
				<li>인증번호는 1588-5200 번호로 고객님께 발송됩니다.</li>
				<li>
					고객님께서 인증번호를 받지 못하신 경우, 스팸메시지함을 확인하실 수
					있도록 안내 바랍니다.
				</li>
			</ul>
			<div class="bottom_box">
				<v-btn
					depressed
					color="primary"
					class="btn_col_1 col-12 mb-0"
					@click="authenticate"
					:disabled="isDisableBtn"
				>
					인증하기
				</v-btn>
			</div>
		</v-container>
	</div>
</template>

<script>
import { sendAuthNo, validAuthNo } from '@/apis/order.ec.api'
import { registerCallbackToApp } from '@/utils/navigation'
import { getPhone } from '@/utils/ec'

export default {
	data() {
		return {
			time: '3:00',
			timerInterval: {},
			authNo: '',
			name: '',
			phone: '',
			rules: {
				authNo: [
					v => (v && v.length === 6) || '인증번호를 정확히 입력해 주세요.'
				]
			}
		}
	},
	created() {
		this.initAppPage()
	},
	mounted() {
		this.startTimer()
		this.name = this.$store.getters['verify/custName']
		this.phone = this.$store.getters['verify/custPhone']
	},
	computed: {
		isDisableBtn() {
			let is = false
			is = this.time === '0:00'
			return is
		}
	},
	methods: {
		getPhone,
		async authenticate() {
			if (!this.$refs.authForm.validate()) {
				return
			}

			if (this.time === '0:00') {
				let confirmRes = await this.$confirm({
					message:
						'인증번호 입력시간이 지났습니다.<br/>인증 메시지를 다시 보내시겠습니까?',
					persistent: false,
					okText: '재전송'
				})
				if (confirmRes) {
					await this.resend()
				}
			} else {
				let params = {
					name: this.name,
					phoneNumber: this.phone,
					certNo: this.authNo
				}

				let res = await validAuthNo(params)

				if (res.resultObject.valid) {
					// 전자계약서 확인 (코디 대행 입력)
					this.$router.push({ name: 'ec-confirm' })
				} else {
					this.$alert({
						message:
							'인증번호가 맞지 않습니다.<br />인증번호를 다시 확인해 주세요.'
					})
				}
			}
		},
		async resend() {
			let params = {
				name: this.name,
				phoneNumber: this.phone
			}

			const res = await sendAuthNo(params)
			this.$log('res:', JSON.stringify(res))
			this.$store.dispatch('ec/setUserKey', res.resultObject.userKey)

			const resendRes = await this.$alert({
				message: this.getPhone(this.phone) + '으로 메시지가 발송되었습니다.'
			})
			this.$log(resendRes)
			// if (resendRes) {
			clearInterval(this.timerInterval)
			this.startTimer()
			// }
		},
		startTimer() {
			let timer = 180

			this.timerInterval = setInterval(() => {
				let minutes = parseInt(timer / 60, 10)
				let seconds = parseInt(timer % 60, 10)

				// minutes = minutes < 10 ? '0' + minutes : minutes
				seconds = seconds < 10 ? '0' + seconds : seconds

				this.time = minutes + ':' + seconds

				if (--timer < 0) {
					clearInterval(this.timerInterval)
				}
			}, 1000)
		},
		initAppPage() {
			registerCallbackToApp({})
		}
	}
}
</script>

<style scoped lang="scss"></style>
